@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

:root {
  --primary-color: #231f20;
  // --primary-color-opacity: rgba(79, 140, 224, 0.5);
}

* {
  outline: none !important;
}

@import './mixins.scss';

body {
  margin: 0;
  font-family: 'Figtree', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .react-tel-input {
    .special-label {
      display: none;
    }
    .form-control {
      padding: 6.5px 11px 6.5px 53px;
      font-size: 16px;
      border-radius: 4px;
      height: 32px;
      width: 100%;
    }
    .flag-dropdown.open {
      .search {
        padding: 10px 20px 6px 10px;
        .search-box {
          width: 100%;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ant-picker {
  width: 100%;
}

.ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: end;
}

.ant-btn {
  box-shadow: none;
}

.ant-btn-primary {
  background-color: var(--primary-color);
}

.ant-btn-background-ghost {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.skills {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .skill {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #231f20;
    background-color: #f4f4f4;
    border: solid 1px #e0e0e0;
    border-radius: 16px;
    padding: 0 8px;
  }
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  display: flex;
  flex-direction: row-reverse;
  max-height: 80px;

  p {
    margin: 0;
  }

  .ant-upload-list-item-container {
    margin: 0;
    height: 80px !important;
    width: 80px !important;
    width: auto;
  }

  .upload-text {
    font-size: 12px;
    line-height: 16px;
    color: #888888;
  }
  .upload-drag-icon {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding-top: 16px;
    color: var(--primary-color);
    .anticon {
      padding-left: 5px;
    }
  }
  .upload-supported {
    position: absolute;
    bottom: 2px;
    left: 2px;
    font-size: 12px;
    line-height: 16px;
    color: #888888;
  }
}

.border-left {
  border-left: 1px solid #f0f0f0;
}

.border-right {
  border-right: 1px solid #f0f0f0;
}

#MainLayout {
  background: linear-gradient(180deg, #420000 0%, #69140e 50.23%, #e5e2cf 100%);
  min-height: 100vh;
  position: relative;

  .CommonFilter {
    background-color: white;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;

    h4 {
      margin: 0;
    }

    .FilterFormHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 8px;
      border-bottom: 1px solid #f4f4f4;

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .FilterFormContent {
      padding-top: 16px;

      .ant-picker-range {
        width: 100%;
      }
    }

    .apply {
      .icon {
        svg path {
          fill: #4f8ce0;
        }
      }
    }
  }

  .BreadCrumb {
    margin: 24px 0;
    display: inline-block;

    a,
    span {
      color: #1d1d1b;
      text-transform: uppercase;
    }
  }

  .Content {
    padding-top: 65px;
    padding-left: 40px;
    padding-right: 40px;

    .page-header-table {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      padding: 12px 24px;

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          height: 32px;
          margin-left: 12px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 16px;
          font-weight: 600;
          font-size: 14px;
          padding: 0px 7px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;

          .count {
            font-size: 12px;
            line-height: 16px;
            color: white;
            font-weight: 700;
            background-color: var(--primary-color);
            border: 1px solid var(--primary-color);
            border-radius: 2px;
            padding: 0 4px;
            margin-right: 12px;
          }

          .icon {
            margin-left: 15px;
            margin-right: 2px;
            margin-top: 4px;
          }
        }
      }

      .search {
        flex-basis: 624px;

        .ant-input-affix-wrapper {
          border-radius: 4px;
        }

        &.full {
          flex-basis: 100%;
        }
      }
    }

    .ContentHeader {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      .HeaderTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        a,
        .Link {
          color: var(--primary-color);
          text-decoration: none;
        }
        .icon > div {
          display: flex;
          align-items: center;
          height: 48px;
        }
      }
      .HeaderToolBar {
        align-items: center;
        justify-content: center;
        display: flex;

        .ToolBarButtons {
          display: flex;
          gap: 16px;
          align-items: center;

          a {
            text-decoration: none;
          }

          .ant-btn {
            background-color: #212121;

            .icon {
              margin-top: 3px;
            }

            &.ant-btn-link {
              background-color: white;
              color: var(--primary-color);
              border: 1px solid var(--primary-color);

              .icon {
                svg path {
                  fill: var(--primary-color) !important;
                }
              }
            }
          }
        }
      }
    }

    .ContentMain {
      background: #fff;
      width: 100%;
      border-radius: 8px;

      .ant-table-wrapper {
        .ant-table-thead {
          tr {
            th {
              background-color: white;

              &::before {
                display: none;
              }
            }
          }
        }

        .ant-table-content,
        .ant-table-container {
          .ant-table-body {
            .ant-table-row.ant-table-row-selected {
              > .ant-table-cell {
                background: none;
              }
            }
            &::-webkit-scrollbar {
              -webkit-appearance: none !important;
              background-color: transparent !important;
              width: 12px !important;
              height: 12px !important;
            }

            &::-webkit-scrollbar-thumb {
              background-clip: padding-box !important;
              background-color: #e5e5e5 !important;
              border-color: transparent !important;
              border-radius: 4px !important;
              border-style: solid !important;
              border-width: 4px !important;
            }

            &::-webkit-scrollbar-thumb:hover {
              background-color: rgba(0, 0, 0, 0.5) !important;
            }
          }
          .group,
          .user {
            padding: 4px 12px;
            background: #f4f4f4;
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            border-radius: 16px;
            margin: 0 2px;
          }
          .actions {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .ant-btn {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              height: 32px !important;

              .icon {
                height: 24px;
                margin-left: 5px;
                color: var(--primary-color);

                svg {
                  width: 24px;
                  height: 24px;
                }
              }

              &.edit {
                color: var(--primary-color);
              }

              &.delete {
                color: #d32f2f;
              }

              &.draft {
                background: #888888;
              }

              &.dispatched {
                background: #ffab00;
              }

              &.optimized,
              &.optimize {
                background: #d32f2f;
              }

              &.error {
                background: #f6d5d5;
                color: #d32f2f;
              }
              &.invite-user {
                color: #00c853;
                .icon {
                  svg path {
                    fill: #00c853;
                  }
                }
              }
              &.manage-users {
                color: #1e88e5;
                .icon {
                  svg path {
                    fill: #1e88e5;
                  }
                }
              }
            }
          }
        }
        .ant-pagination {
          margin: 16px 8px;

          li {
            &:first-child {
              flex: 1;
            }
            &:last-child {
              flex: 1;
              div {
                float: right;
              }
            }
          }

          .ant-pagination-item,
          .ant-pagination-prev,
          .ant-pagination-next {
            border: none;
            a {
              border: 1px solid var(--primary-color);
              border-radius: 2px;
            }
          }

          .ant-pagination-item:hover a,
          .ant-pagination-item-active a {
            background-color: var(--primary-color);
            color: #fff;
          }

          .ant-select-selector {
            border-radius: 4px;
            background: #ffffff;
            height: 32px !important;
            .ant-select-selection-item {
              margin-right: 10px;
              height: 32px !important;
              line-height: 32px !important;
            }
          }
        }
      }
    }

    .CommonFilter {
      background-color: white;
      padding: 16px;
      border-bottom: 1px solid #e0e0e0;

      h4 {
        margin: 0;
      }

      .FilterFormHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        border-bottom: 1px solid #f4f4f4;

        .actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .FilterFormContent {
        padding-top: 16px;
      }

      .apply {
        .icon {
          svg path {
            fill: #4f8ce0;
          }
        }
      }
    }
  }

  &.is-admin {
    background: #f4f4f4;
  }

  .BreadCrumb {
    margin: 24px 0;
    display: inline-block;

    a,
    span {
      color: #1d1d1b;
      text-transform: uppercase;
    }
  }

  .status {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    max-width: 200px;
    min-width: 64px;
    border-radius: 12px;

    &.order,
    &.fleet {
      border-radius: 0;
    }

    &.fleet {
      min-width: 80px;
    }

    &.active {
      background-color: #e6faee;
      color: #00c853;
      border: solid 1px #00c853;
    }

    &.inactive,
    &.unfunctional {
      background-color: #ffece8;
      color: #ff3d17;
      border: solid 1px #ff3d17;
    }

    &.van {
      color: #e47669;
      background-color: #fcf1f0;
      border: solid 1px #e47669;
    }

    &.nano_hub {
      background-color: #edf3fc;
      color: #4f8ce0;
      border: solid 1px #4f8ce0;
    }

    &.micro_hub,
    &.trailer {
      color: #face63;
      background-color: #fefaef;
      border: solid 1px #face63;
    }
    &.city_hub,
    &.module {
      color: #00c853;
      background-color: #e6faee;
      border: solid 1px #00c853;
    }

    &.bike_park,
    &.bike {
      color: #8590e3;
      background-color: #f3f4fc;
      border: solid 1px #8590e3;
    }

    &.draft,
    &.geocoding,
    &.maintenance {
      background: #ffffff;
      border: 1px solid #888888;
      color: #888888;
    }

    &.error {
      color: #ff3d17;
      background-color: white;
      border: 1px solid #ff3d17;
    }

    &.unassigned,
    &.unscheduled {
      background: #ebebeb;
      color: #888888;
    }
    &.assigned,
    &.dispatched {
      background: #93bfff;
      color: white;
    }
    &.pickup_arrived,
    &.delivery_arrived {
      background: #daecfb;
      color: #1e88e5;
    }
    &.in_transit,
    &.optimized,
    &.going_next {
      background: #f9efdb;
      color: #ffab00;
    }
    &.collected {
      background: #edf3fc;
      color: #4f8ce0;
      border: 1px solid #4f8ce0;
    }
    &.completed,
    &.functional {
      background: #daf3e4;
      color: #00c853;
    }
    &.failed,
    &.delivery_failed,
    &.pickup_failed {
      background: #f6e1e1;
      color: #e53935;
    }
    &.sent {
      background: #ffab00;
      color: #ffffff;
    }
    &.delivered {
      background: #daf3e4;
      color: #00c853;
    }
    &.undelivered {
      background: #f6e1e1;
      color: #e53935;
    }
    &.dispatched {
      background: #d8cbee;
      border: 1px solid #e0e0e0;
      color: #7732ef;
    }
  }

  .ant-layout-header {
    padding: 0;
    background: white;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f2f5;
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 52px;
    line-height: 52px;
    align-items: center;

    .logo {
      padding-left: 40px;
      height: 52px;
      line-height: 52px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      img {
        max-width: 141px;
        height: 32px;
      }

      span {
        margin-left: 10px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        padding: 4px 7px;
        background-color: #fff;
        color: #1e88e5;
        border-radius: 11px;
      }
    }

    .account-info {
      display: flex;
      padding: 0 10px;
      align-items: center;
      justify-content: center;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        img {
          max-width: 32px;
          border-radius: 50%;
        }
      }
      .info {
        margin: auto 0;
        padding: 10px;
        .fullname {
          line-height: 16px;
          font-size: 12px;
          font-weight: 600;
          color: #231f20;
        }
        .email {
          line-height: 16px;
          font-size: 10px;
          cursor: pointer;
          color: #231f20;
        }
      }

      span {
        color: #231f20;
        max-width: 10px;
        margin-left: 50px;
      }

      .ant-badge {
        margin: auto 10px;
        margin-right: -15px;
        .ant-badge-count {
          height: 24px;
          min-width: 24px;
          line-height: 24px;
          border-radius: 12px;
          .ant-scroll-number-only {
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.ant-modal-body {
  .FormHeader {
    line-height: 22px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      margin-left: -24px;
      border-left: 4px solid var(--primary-color);
      padding-left: 20px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #212121;
    }
    .right {
      a {
        display: flex;
        .icon > div {
          line-height: 24px;
          height: 24px;
          padding-left: 12px;
        }
      }
    }
  }

  .FormContent {
    label {
      font-weight: 600;
      font-size: 12px;
    }

    .ant-picker-range {
      width: 100%;
    }

    .item-label {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;

      .right {
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #888888;
      }
    }
  }

  .FormFooter {
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: -24px;
    margin-right: -24px;
    gap: 12px;
    box-shadow: 0px -1px 0px #f4f4f4;

    .ant-btn {
      height: 48px !important;
      box-shadow: none;
      font-weight: 600;
    }

    .btn-i-got-this {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary-color);
      background: #ffffff;
      border: 1.5px solid var(--primary-color) !important;
      border-radius: 4px;

      .icon {
        height: 24px;
        margin-left: 20px;

        svg path {
          fill: var(--primary-color) !important;
        }
      }
    }

    .Left {
      .delete {
        border: 1px solid #e53935 !important;
      }
    }

    .Right {
      display: flex;
      .ant-btn {
        // min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          height: 24px;
          margin-left: 20px;
        }
      }
    }
  }
}

.ant-popover-content {
  .ant-popconfirm-buttons {
    display: flex;
  }
}

.popupErrorMessages {
  .item {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 0;
  }
}
