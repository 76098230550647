.tags-input {
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .tag-input {
    width: auto;
    flex-grow: 1;
    border: none;
    font-size: 16px;
  }
  .edit-tag {
    border-radius: 5px;
    margin: 5px 8px 5px 5px;
    font-size: 16px;
    line-height: 1.5;
  }
}
