.MainHeader {
  .ant-dropdown {
    max-width: max-content;

    .ant-dropdown-menu {
      padding: 0;

      li {
        padding: 0;
      }
    }
  }
}
