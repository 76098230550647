.OrderDetailPage {
  .white {
    background-color: white;
  }

  .border-bottom {
    border-bottom: 1px solid #f4f4f4;
  }

  .border-top {
    border-top: 1px solid #f4f4f4;
  }

  .border-left {
    border-left: 1px solid #f4f4f4;
  }

  label {
    font-size: 12px;
    line-height: 16px;
    color: #888888;
    font-weight: 700;
    margin-bottom: 4px;
  }

  .order-content {
    font-size: 16px;
    line-height: 24px;
    color: #1d1d1b;
    font-weight: 400;

    &.hasCopy {
      cursor: pointer;
    }
  }

  .icon {
    margin: 0;
    display: flex;
    align-items: center;

    svg {
      max-width: 12px;
      max-height: 12px;
    }

    &.upload {
      svg {
        max-width: 16px;
        max-height: 16px;
      }
    }
  }

  .hasCopy {
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-left: 12px;
    }
  }

  .OrderInfo {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .OrderInfoHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        display: flex;
        align-items: center;
        gap: 12px;

        &.left {
          justify-content: flex-start;

          .orderId {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;

            img {
              margin-left: 12px;
            }
          }
        }

        &.right {
          justify-content: flex-end;

          .headerActions {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .orderButton {
              margin: 0;
              padding: 8px 12px;
              border: 1px solid #231f20;

              span {
                margin-right: 12px;
                font-size: 12px;
                line-height: 16px;
              }

              .icon {
                margin-left: 0;
                margin-top: 0px;

                path {
                  fill: white;
                }
              }

              &.ButtonWhite {
                .icon {
                  path {
                    fill: var(--primary-color);
                  }
                }
              }
            }
          }
        }
      }
    }

    .labels {
      display: flex;
      align-items: center;
      gap: 8px;

      .label {
        display: flex;
        align-items: center;

        > span {
          display: inline-block;
          padding: 4px 8px;
          font-size: 12px;
          color: #1d1d1b;
          border-radius: 1px;

          &.plus {
            cursor: pointer;
            border: solid 1px #e0e0e0;

            .icon {
              display: flex;
              align-items: center;
              line-height: 14px;

              > div {
                max-width: 100%;

                svg path {
                  fill: #1d1d1b;
                }
              }
            }
          }

          > .rectangle {
            width: 8px;
            height: 8px;
            background: black;
            display: inline-block;
            margin-right: 4px;
          }

          &.Overnight {
            background-color: #7732ef1f;

            > .rectangle {
              background: #7732ef;
            }
          }

          &.CustomerReturn {
            background-color: #ffab001f;

            > .rectangle {
              background: #ffab00;
            }
          }

          &.HubPickup {
            background-color: #1e88e51f;

            > .rectangle {
              background: #1e88e5;
            }
          }
        }
      }
    }

    .types {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      .type {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        background-color: #f4f4f4;
        border: 1px solid #e0e0e0;
        border-radius: 16px;
        padding: 0 8px;
      }
    }
  }

  .statuses-log {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .Rider {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        color: #1d1d1b;
      }

      .Text {
        display: inline;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #212121;
      }

      .button-history {
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 12px;

          span {
            font-size: 12px;
            line-height: 16px;
          }

          .icon {
            width: 13px;
            margin: 0;

            svg {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .ant-steps-item-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-steps-item-title {
        font-size: 14px;
        font-weight: 600;
        padding-top: 8px;

        .status {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          padding: 4px 12px;
          border-radius: 2px;

          &.assigned {
            color: var(--primary-color);
            background: #e8dff7;
          }
          &.collected {
            background: #e4f1fc;
            color: #1e88e5;
          }
          &.in-transit {
            background: #f9efdb;
            color: #ffab00;
          }
          &.completed {
            background: #daf3e4;
            color: #00c853;
          }
        }
      }
      .ant-steps-item-description {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
        width: max-content;
        padding-top: 8px;
      }
    }

    .ant-steps-item-wait {
      .orderStatus {
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        color: #888888;
        background-color: #f1f1f1;
        padding: 4px 7px;
      }
      .ant-steps-icon {
        display: none;
      }
    }

    .ant-steps-item {
      &.ant-steps-item-active {
        .ant-steps-item-icon {
          .ant-steps-icon {
            display: none;
          }
        }
      }

      &.ant-steps-item-finish {
        .ant-steps-item-icon {
          background-color: #4f8ce0;
          border-color: #4f8ce0;

          .ant-steps-icon {
            background-color: #4f8ce0;
            border-color: #4f8ce0;
            color: white;
          }
        }
      }
    }
  }

  .super-note {
    padding: 24px;
    font-size: 14px;
    list-style: 24px;

    b {
      margin-right: 8px;
    }
    p {
      margin: 0;
    }
  }

  .PartnerInfo {
    padding: 24px;
    .header {
      img {
        max-width: 24px;
        max-height: 24px;
      }

      h5 {
        margin: 0;
      }

      &.left {
        .img {
          display: inline-block;
          vertical-align: top;

          img {
            vertical-align: center;
            margin-right: 8px;
          }
        }

        .text {
          display: inline-block;
          h5 {
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #1d1d1b;
          }
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      &.actions {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        .ant-btn-link {
          span {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #4f8ce0;
          }

          .icon {
            margin-left: 0;
            margin-top: 0;

            path {
              fill: #4f8ce0;
            }
          }
        }
      }
    }
  }

  .DeliveryDetail {
    .wrapper {
      padding: 24px;

      .title {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
      }

      .content {
        display: flex;
        gap: 4px;
        flex-wrap: wrap;

        .img {
          max-width: 140px;
          max-height: 140px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .wrapper-map {
    height: 100%;
  }
}

.OrderDetailModal {
  .OrderForm,
  .PickupForm,
  .DeliveryForm {
    border: none;
    padding: 0;
  }
}

.share-content-order {
  .actions {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
      margin: 0;
      height: 24px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: #1d1d1b;

      .icon {
        margin: 0;
      }
    }
  }
}
