.HubForm {
  .ContentHeader {
    .ToolBarButtons {
      .ant-btn {
        background-color: #212121;

        .icon {
          margin-top: 8px;
        }
      }
    }
  }
}
