.MainMenu.ant-menu-horizontal {
  border: none;
  min-width: 1136px;
  background-color: white;

  li.ant-menu-item {
    display: flex;
    gap: 41px;
    align-items: center;
    padding: 0;
    margin: 0;
    top: 0;
    background-color: white;
    color: #231f20;
    font-weight: normal;

    .ant-menu-title-content {
      height: 52px;
      padding: 8px 0px;
      min-width: max-content;
    }

    .MenuItemContent {
      display: flex;
      align-items: center;
      padding-top: 7px;
      justify-content: center;
      min-width: 142px;

      .icon {
        line-height: 24px;
        height: 24px;
        padding-right: 4px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      a {
        font-size: 14px;
        line-height: 24px;
        color: #231f20;
      }
      .icon {
        svg path {
          fill: #e0e0e0;
        }
      }
    }

    &.ant-menu-item:hover {
      a {
        font-weight: 700;
      }
      &:after {
        border-bottom: 4px solid var(--primary-color);
        right: 0;
        left: 0;
      }
      .icon {
        svg path {
          fill: var(--primary-color);
        }
      }
    }

    &.ant-menu-item-selected {
      a {
        font-weight: 700;
      }

      &:after {
        border-bottom: 4px solid var(--primary-color);
        right: 0;
        left: 0;
      }
      .icon {
        svg path {
          fill: var(--primary-color);
        }
      }
    }
  }

  @media (max-width: 1000px) {
    &.has-pricing {
      min-width: auto;
    }
  }
}

.ant-menu-submenu-popup {
  li.ant-menu-item {
    display: flex;
    gap: 41px;
    align-items: center;
    padding: 0;
    margin: 0;
    top: 0;
    background-color: white;
    color: #231f20;
    font-weight: normal;

    .ant-menu-title-content {
      height: 52px;
      padding: 8px 0px;
      min-width: max-content;
    }

    .MenuItemContent {
      display: flex;
      align-items: center;
      padding-top: 7px;

      .icon {
        line-height: 24px;
        height: 24px;
        padding-right: 4px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      a {
        font-size: 14px;
        line-height: 24px;
        color: #231f20;
      }
      .icon {
        svg path {
          fill: #e0e0e0;
        }
      }
    }

    &.ant-menu-item:hover {
      a {
        font-weight: 700;
      }
      &:after {
        border-bottom: 4px solid var(--primary-color);
        right: 0;
        left: 0;
      }
      .icon {
        svg path {
          fill: var(--primary-color);
        }
      }
    }

    &.ant-menu-item-selected {
      a {
        font-weight: 700;
      }

      &:after {
        border-bottom: 4px solid var(--primary-color);
        right: 0;
        left: 0;
      }
      .icon {
        svg path {
          fill: var(--primary-color);
        }
      }
    }
  }
}

.BreadCrumb {
  margin: 24px 0;
  display: inline-block;

  a {
    color: #1d1d1b;
  }

  ol {
    li {
      span.ant-breadcrumb-link {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
      }

      &.ant-breadcrumb-separator {
        color: #1d1d1b;
        display: flex;

        span {
          display: flex;
        }
      }

      &:last-child {
        span.ant-breadcrumb-link {
          color: #4f8ce0;

          a {
            color: #4f8ce0;
          }
        }
      }
    }
  }
}

.ant-btn:not(.ant-input-search-button) {
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 24px;

  span {
    font-weight: 600;
    line-height: 24px;
    font-size: 16px;
    padding-bottom: 2px;
  }

  &.ant-btn-sm {
    height: 32px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 15px;

    span {
      font-size: 14px;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    height: 24px;
    margin-left: 15px;
    margin-right: 2px;
    margin-top: 4px;
  }
}
