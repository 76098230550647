.SchedulerPage {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      color: #231f20;
      margin: 0;
    }
  }

  .navigation {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      background-color: white;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      text-align: center;
      border: 1px solid #e0e0e0;
      border-radius: 1px;
    }

    .next {
      cursor: pointer;
      border: none;
      padding: 8px;
      line-height: 10px;
    }
  }

  .schedule {
    display: flex;
    flex-direction: column;
    background-color: white;

    .info {
      width: 176px;
      padding-left: 16px;
      padding: 10px 0 10px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .fullName {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }
    }

    .scheduled-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      padding: 16px;
    }

    .days-header {
      display: flex;
      border-bottom: 1px #e0e0e0 solid;

      .day {
        flex: 1;
        text-align: center;
        border-left: 1px #e0e0e0 solid;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        padding: 12px 0;
      }
    }

    .shift-row {
      display: flex;
      align-items: center;
      border-top: solid 1px #e0e0e0;
      min-height: 56px;
      box-sizing: border-box;

      .shift-detail {
        flex: 1;
        min-height: 40px;
        padding: 8px;
        border-left: solid 1px #e0e0e0;
        display: flex;
        align-items: center; /* Align children vertically within .shift-detail */
        justify-content: center; /* Optional: Center content horizontally within .shift-detail */
        height: 100%; /* Make .shift-detail follow the height of .shift-row */

        .shift-content {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 4px;
          width: 100%;

          &.add {
            border: 1px solid #e0e0e0;
            padding: 8px;
            display: none;

            &:hover {
              display: block;
            }

            .icon {
              svg {
                path {
                  fill: #e0e0e0;
                }
              }
            }
          }

          &:hover {
            &.add {
              display: block;
            }
          }

          p,
          b {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
          }

          .bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        &:hover {
          .shift-content.add {
            display: flex;
          }
        }
      }
    }
  }
  .today-border {
    border-left: #231f20 solid 1px !important;
    border-right: #231f20 solid 1px !important;
  }
}
