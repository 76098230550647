.OrderForm,
.UploadForm,
.OrderInfoForm {
  margin-bottom: 48px;
  .FormContent {
    margin-bottom: 24px;

    label {
      font-weight: 600;
      font-size: 12px;
    }

    .TimeInput {
      height: 48px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      padding: 6.5px 11px 6.5px;

      &:focus {
        border-color: #40a9ff;
        outline: 0;
      }
    }

    .Field {
      display: flex;
      justify-content: space-between;

      .FieldName {
        width: 30%;
        .ant-input {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
      .FieldValue {
        width: 70%;
        .ant-input {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      .FieldAction {
        width: 40px;
        margin-left: 12px;

        button {
          padding: 10px 8px;
        }
      }
    }

    .AddCustomFieldBtn {
      height: 48px;
      border: none;
      box-shadow: none;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1.5px solid var(--primary-color);
      box-sizing: border-box;
      border-radius: 4px;
      color: var(--primary-color);

      .icon {
        height: 24px;
        margin-left: 20px;
        svg path {
          fill: var(--primary-color);
        }
      }
    }
  }

  .FormFooter {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -1px 0px #f4f4f4;
    border-radius: 0px 0px 4px 4px;
    padding: 12px 24px;
    background: #fff;

    .ant-btn {
      height: 48px;
      border: none;
      border-radius: 4px;
      box-shadow: none;
      font-weight: 600;
    }

    .Left {
      .delete {
        border: 1px solid #e53935;
      }
    }

    .Right {
      display: flex;
      .ant-btn {
        min-width: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          height: 24px;
          margin-left: 20px;

          svg {
            path {
              fill: var(--primary-color);
            }
          }
        }
      }
    }
  }

  .UploadOrders {
    .ant-upload.ant-upload-drag {
      height: 160px;
      .upload-text {
        font-size: 12px;
        line-height: 16px;
        color: #888888;
      }
      .upload-drag-icon {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        padding-top: 16px;
        color: var(--primary-color);
        .anticon {
          padding-left: 5px;
        }
      }
      .upload-supported {
        position: absolute;
        bottom: 2px;
        left: 2px;
        font-size: 12px;
        line-height: 16px;
        color: #888888;
      }
    }
    .download-text {
      padding-top: 16px;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      > div {
        padding-right: 6px;
      }

      .ant-btn-link {
        color: var(--primary-color);
        padding: 0;
      }
    }
    .NoOrdersYet {
      text-align: center;
      padding: 20px;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #888888;
    }
    .AddNewOrders {
      display: flex;
      justify-content: center;
      .ant-btn {
        height: 48px;
        margin-left: 20px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 24px;
        font-weight: 600;
        font-size: 14px;

        .icon {
          height: 24px;
          margin-left: 20px;
        }
      }

      .AddFromContactList {
        border: 1px solid var(--primary-color);
        background: #ffffff;
        color: var(--primary-color);
        .icon {
          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }
  }
}

.OrderInfoForm {
  margin-bottom: 0;
  .FormContent {
    padding: 0;
    .SectionHeader {
      .Left {
        font-weight: 600;
        font-size: 12px;
        padding: 0 0 8px;
      }
    }
  }
  .FormFooter {
    padding-bottom: 0;
  }
}

.OrderForm {
  .FormContent {
    padding-bottom: 0;
    border-bottom: 1px solid #e0e0e0;
    .Section {
      .SectionHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: -24px;
        padding-left: 20px;
        border-left: 4px solid var(--primary-color);
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #212121;

        .Right {
          button {
            display: flex;
            align-items: center;

            span {
              color: #212121;
            }
            .icon > div {
              line-height: 24px;

              svg {
                path {
                  fill: var(--primary-color);
                }
              }
            }
          }
        }
      }
    }
  }
}
