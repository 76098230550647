.UploadOrders {
  .ant-upload-wrapper {
    height: 152px;
  }

  .header-table {
    text-align: left !important;
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    .header-table {
      text-align: left !important;
    }
  }
  .order-table td,
  .order-table th {
    border: 0.25px solid #ddd;
    padding: 8px;
  }

  .order-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .order-table tr:hover {
    background-color: #ddd;
  }

  .order-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #1e88e5;
    color: white;
  }

  .bottom-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .upload-supported {
    padding-left: 8px;
  }

  .ContentMain {
    border-radius: 0 0 4px 4px !important;

    .ant-upload-wrapper {
      padding: 24px;
      min-height: 160px;
    }
  }

  .ContentTitle {
    background: white;
    padding: 24px 0;
    border-radius: 4px 0;
    border-bottom: solid 1px #e0e0e0;

    h2 {
      margin: 0;
      border-left: 4px solid #4f8ce0;
      padding-left: 20px;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .sample {
    p {
      margin: 0;
    }

    padding: 24px;
  }
}
