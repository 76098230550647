.m-active {
  transition: all 0.3s infinite;
  animation: jump 0.6s infinite;
}
.icon-MarkerStart,
.icon-MarkerEnd,
.icon-MarkerStop,
.icon-MarkerDropoff,
.icon-MarkerPickup {
  position: relative;

  svg {
    max-width: 24px;
  }

  span {
    position: absolute;
    text-align: center;
    top: 14px;
    font-size: 10px;
    font-weight: 900;
    left: 0;
    right: 0;
  }
}

.icon-MarkerStop {
  svg {
    width: 18px;
  }

  span {
    top: 4px;
  }
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(-4px);
  }
}
