.RoutePlanLeft {
  background: var(--primary-color);
  width: 64px;
  height: calc(100% - 16px);
  padding: 16px 0px 0 8px;
  position: fixed;

  &__Top {
    text-align: center;
    padding: 4px 8px;
    margin-right: 8px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 4px;
    margin-bottom: 16px;
    outline-offset: 0;

    a,
    a:link,
    a:visited,
    a:focus,
    a:hover,
    a:active {
      text-decoration: none;
    }

    &__Title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
    }
    &__Sub {
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
  }

  .RoutePlanMenu.ant-menu-vertical {
    border: none;
    box-shadow: none;
    background: none;

    .ant-menu-item {
      height: auto;
      text-align: center;
      margin: 0;
      width: 100%;

      .ant-menu-title-content {
        width: 100%;
        text-align: center;

        > a {
          width: 100%;
        }

        .Title {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
        }

        .Count {
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      &.ant-menu-item-selected {
        background: #fff;
        border-radius: 4px 0 0 4px;

        .Title,
        .Count {
          color: var(--primary-color);
        }

        .icon svg path {
          fill: var(--primary-color);
        }
      }

      &.add-order {
        margin-top: -14px;
        height: 40px;
      }
    }
  }
}
