.RoutesChart {
  padding-top: 52px;
  height: calc(100vh - 52px);
  display: flex;
  background-color: white;
  position: relative;
  user-select: none;

  .select-date {
    position: absolute;
    width: max-content;
    top: 68px;
    left: 84px;
    z-index: 2;
  }

  > .wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 72px);
    margin-left: 72px;

    > .wrapper-map {
      flex: 1;
      height: 100%;
      width: 100%;
    }

    > .wrapper-chart {
      height: 200px;

      .ChartContent {
        border-top: 1px solid #f4f4f4;
        .td {
          float: left;
          display: flex;
        }

        .tr {
          display: flex;
          clear: both;
          height: 48px;
        }

        .scrollCustom.tbody {
          overflow: auto;
          max-height: 152px;
        }

        div.tbody > div.tr:nth-child(1) {
          position: sticky;
          top: 0;
          z-index: 99;
        }

        div.tbody > div.tr > div:nth-child(1) {
          position: sticky;
          left: 0;
          z-index: 90;

          &.isDragging {
            position: unset;
            z-index: unset;
          }
        }

        .tbody .tr:nth-child(odd) .td {
          background: #fff;
        }

        .tbody .tr:nth-child(even) .td {
          background: #f4f4f4;
        }

        .unscheduled {
          border-bottom: 1px solid #e0e0e0;
        }

        .time {
          padding: 10px 0;
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 0 16px;

          .Icon {
            padding: 8px;
            cursor: pointer;

            &.disabled {
              cursor: not-allowed;
              color: #e0e0e0;
            }
          }
        }

        .driver-name {
          width: 267px;
          padding: 12px 16px;
          overflow: hidden;
          white-space: nowrap;
          display: flex;
          align-items: center;
          color: #212121;
          text-align: center;

          b {
            width: 50%;
          }
          span {
            margin-right: 16px;
            cursor: pointer;
          }
          span.swap {
            margin-left: 5px;
            margin-right: 0;
          }
          .name {
            width: 128px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            font-weight: 800;
            font-size: 12px;
            line-height: 16px;
          }
          .capacity {
            .text {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }

        .driver-row {
          height: 48px;
          position: relative;
          display: flex;
          margin-left: 17px;
          align-items: center;

          .truck-start,
          .truck-end {
            position: absolute;
            z-index: 2;
            cursor: pointer;

            &.truck-start {
              top: 7px;
              img {
                height: 34px;
              }
            }

            &.truck-end {
              top: 10px;
              img {
                height: 26px;
              }
            }
          }

          .steps {
            position: absolute;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
          }

          .stepsDone {
            position: absolute;
            width: 1px;
            height: 48px;
            display: flex;
            align-items: center;
            z-index: 1;
          }

          .all-minutes {
            border-left: 1px solid #e0e0e0;
            display: flex;
            align-items: center;
            .minutes {
              height: 48px;
              border-right: 1px solid #e0e0e0;
            }
          }

          .line {
            top: 23px;
            height: 2px;
            position: absolute;
            border-width: 1px;
            z-index: 1;
          }
        }

        .unit {
          background-color: #fff;
          border: 1px solid #888888;
          border-radius: 24px;
          width: 24px;
          height: 24px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          position: relative;

          .anticon {
            position: absolute;
            bottom: -8px;
            left: -4px;

            svg {
              height: 13px;
            }
          }

          &.unit2 {
            border-radius: 3px;
            .anticon {
              left: -8px;
            }
          }
        }
      }
    }

    > .summary-routes {
      p {
        margin: 0;
      }

      border-top: solid 1px #e0e0e0;
      background-color: white;
      display: flex;
      padding: 12px;
      gap: 16px;
    }

    .actions-route-plan {
      height: 56px;
      padding: 6px 16px 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border-top: 1px solid #e8e8e8;

      .right {
        display: flex;
        gap: 16px;

        .btn-dispatch-route {
          color: #fff;
          background-color: #00c853;
        }
      }
    }
  }

  > .wrapper-orders {
    width: calc(100% - 72px);
    height: calc(100vh - 52px);
    position: relative;
    background-color: white;
    margin-left: 72px;

    .title {
      margin: 24px 0;
      padding: 0 24px;
      font-size: 16px;
      line-height: 24px;
      border-left: 4px solid #4f8ce0;
    }

    .actions-orders {
      height: 44px;
      position: fixed;
      bottom: 0;
      left: 72px;
      right: 0;
      border-top: 1px solid #e8e8e8;
      display: flex;
      justify-content: space-between;
      padding: 12px 16px;
      z-index: 2;
      background-color: white;

      .right {
        display: flex;
        gap: 16px;
      }
    }
  }
}
