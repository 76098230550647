.autocomplete-dropdown-container {
  background-color: #fff;
  position: absolute;
  z-index: 999;
  width: max-content;
  top: 45px;

  .ant-menu {
    border: 1px solid #ddd;
    border-radius: 0;
    margin: 0;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);

    li {
      padding: 0;
      margin: 0;
      border-radius: 0;
      border-bottom: 1px solid #ddd;
      width: 100%;
    }
  }

  .suggestion-item,
  .suggestion-item--active {
    padding: 5px;
    // border: 2px solid rgba(211, 47, 47, 0.2);
    line-height: 35px;
    border-top: none;
    border-bottom: none;

    &:hover {
      background-color: #f4f4f4;
    }

    &:last-child {
      // border-bottom: 2px solid rgba(211, 47, 47, 0.2) !important;
      border-radius: 0 0 5px 5px;
    }

    &:first-child {
      // border-top: 2px solid rgba(211, 47, 47, 0.2) !important;
    }
  }
}

.autocomplete-dropdown-container:empty {
  display: none;
}
