.SignInForm,
.ForgotPasswordForm,
.ResetPasswordForm {
  background: #ffffff;
  color: #212121;

  @media only screen and (max-width: 425px) {
    height: 100vh;
  }

  .Logo {
    padding: 24px;
    padding-bottom: 20px;
  }

  .FormHeader {
    padding: 0 24px;

    .Title {
      font-size: 24px;
      line-height: 40px;
    }

    .SubTitle {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .FormContent {
    padding: 24px;
    padding-top: 20px;
    padding-bottom: 0;

    .ant-form-item {
      margin-bottom: 20px;
    }

    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .FormFooter {
    padding: 24px 24px 24px 24px;

    button {
      font-size: 14px;
      background: var(--primary-color);
      border-radius: 4px;
      border-color: var(--primary-color);
    }
  }

  .HintPassword {
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 10px;

    .HintTitle {
      color: #212121;
      padding-bottom: 5px;
    }

    &.Invalid {
      color: #888888;
    }
  }

  .Terms {
    text-align: center;
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 16px;
  }

  .ForgotPassword {
    a {
      color: var(--primary-color);
    }
  }

  .Register {
    text-align: center;
    padding-bottom: 24px;

    a {
      color: var(--primary-color);
      font-weight: bold;
    }
  }

  .Documentation,
  .Company {
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 24px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);

    .Left {
      .Title {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #212121;
        img {
          padding-right: 5px;
        }
      }

      .Desc {
        font-size: 12px;
        line-height: 16px;
        color: #888888;
        padding-top: 8px;
      }
    }

    &.Company {
      position: unset;
      margin: 8px 0;
      width: 100%;

      .Left {
        .Desc {
          text-transform: capitalize;
        }
      }
    }
  }
}

.SignInForm {
  min-height: 492px;
}
