.HubsPage {
  .map-view {
    height: 605px;
    width: 100%;
    display: flex;
    align-items: center;

    > .wrapper-map {
      flex: 1;
      height: 100%;
    }

    > .fleets-list {
      width: 40%;
      height: 100%;
      background-color: white;

      .title {
        margin: 24px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          margin: 0;
          padding-left: 24px;
          border-left: 4px solid #4f8ce0;
        }

        button {
          color: #888888;
        }
      }
    }
  }

  .ContentSelect {
    border-bottom: solid 1px #f0f0f0;
    .ant-menu-horizontal {
      min-width: 200px;
      padding: 3px 24px;

      > li:first-child {
        margin-right: 40px;
      }
    }
    .ant-menu-item {
      min-width: max-content;

      &:after {
        inset-inline: 0;
      }
    }

    .MenuItem {
      min-width: max-content;
    }

    .ant-menu-item-selected {
      &:after {
        border-bottom: 2px solid var(--primary-color) !important;
      }
    }

    .ant-menu-item:hover {
      &:after {
        border-bottom: 2px solid var(--primary-color) !important;
      }
    }
  }
}
