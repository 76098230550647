.gm-style-iw-ch {
  padding-top: 0;
  .gm-title {
    padding-top: 17px;
  }
  .info-header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 9px 0;
    font-weight: 600;
  }
}

.gm-style-iw-d {
  .info-window {
    position: relative;
    max-width: 800px;

    .info-icon {
      width: 20px;
      height: 20px;
    }

    .info-id {
      font-weight: bold;
      color: #4caf50; /* Matching the green color */
    }

    .info-detail {
      margin-bottom: 4px;
      display: flex;
      gap: 16px;
      line-height: 24px;
      font-size: 12px;
      border-bottom: 1px solid #eceff1;

      strong {
        text-transform: capitalize;
        width: 90px;
        font-weight: 700;
      }

      span {
        flex: 1;
        color: #212121;
        padding-right: 30px;
      }

      b,
      strong {
        color: #888888;
      }

      &:has(.steps) {
        flex-direction: column;

        .steps {
          display: flex;
          gap: 8px;
          flex-direction: column;

          .step {
            display: flex;
            align-items: center;
            gap: 8px;
            border: 1px solid #e0e0e0;
            padding: 4px;
            cursor: pointer;

            > .pickup,
            > .delivery {
              min-width: 24px;
              width: max-content;
              text-align: center;
            }

            .pickup {
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
