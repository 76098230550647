body {
  background: #f4f4f4;
  .SignInPage,
  .SignUpPage,
  .ForgotPasswordPage,
  .ResetPasswordPage {
    margin-top: 100px;
    margin-bottom: 50px;

    .ant-btn {
      margin-left: 0;

      span {
        margin: auto;
      }
    }

    .LeftImg {
      height: 100%;
    }
    @media only screen and (max-width: 425px) {
      margin: 0px;
    }
  }

  .SignInPage .LeftImg {
    min-height: 492;
  }
}
